





















































































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Notification extends Vue {
  public rows = 50;
  public perPage = 10;
  public currentPage = 1;
}
